import React from 'react'
import './slide.scss'
import {ProjectDetails, Button, Scroll} from '../components'

export const FashionBlog = () => {
  const project = {
    description: 'Blog about fashion industry.',
    responsibilities: ['Develop responsive html-code.'],
    technologies: [
      'Html,',
      'Css.',
    ],
  }

  return (
    <div className="slide long">
      <div className="slide-inner fashion-blog">
        <div className="column blue transparent">
          <div className="content">
            <h2>Blog</h2>
            <ProjectDetails project={project}/>
            <Button href="https://annachu.ru/fashionblog" title='View Demo' color='white'/>
          </div>
        </div>
        <Scroll />
      </div> 

      <div className="slide-inner fashion-blog-phone">
        <div className="column blue transparent">
          <div className="content">
            <h2>Blog</h2>
            <span>Adaptive Layout:</span>
            <p>Layout is made for any size of computers and mobile devices using media queries.</p>
            <p>The entire layout is optimized for mobile devices.</p>
            <p>The minimum width of 320 pixels allows you to view the site even on the most strange phones.</p>
          </div>
        </div>
      </div>

    </div>
  )
}
