import React from 'react'
import './textarea.scss'

export const Textarea = (props) => {
	return (
		<textarea 
			data-type={props.type} 
			className={props.error ? 'error' : ' '}
			value={props.value} 
			onChange={props.onChange} 
			placeholder={props.placeholder}
		/>
	)
}