import React from 'react';
import './social.scss'

export const Email = () => (
	<a href="mailto:mail@annachu.ru" target="_blank" className="social">
    <div className="tooltip">Email</div>
    <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0ZM31.4998 13.4998C32.7373 13.4998 33.7498 14.5123 33.7498 15.7498V29.2498C33.7498 30.4873 32.7373 31.4998 31.4998 31.4998H13.4998C12.2623 31.4998 11.2498 30.4873 11.2498 29.2498V15.7498C11.2498 14.5123 12.2623 13.4998 13.4998 13.4998H31.4998ZM22.4998 21.3748L31.4998 15.7498H13.4998L22.4998 21.3748ZM13.4998 17.9998L22.4998 23.6248L31.4998 17.9998V29.2498H13.4998V17.9998Z"/>
    </svg>
  </a>
)