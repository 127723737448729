import React from 'react'
import './slide.scss'
import {ProjectDetails, Button, Scroll } from '../components'

export const Afrianska = () => {
  const project = {
    description: 'Web site for company, wich develop smart home systems.',
    responsibilities: ['Develop responsive html-code with validation of form.'],
    technologies: [
      'Html,',
      'Css,',
      'jQuery.',
    ],
  }

  return (
    <div className="slide long">
      <div className="slide-inner afrianska">
        <div className="column blue transparent">
          <div className="content">
            <h2>Afrianska</h2>
            <ProjectDetails project={project}/>
            <Button href="https://annachu.ru/afrianska" title='View Demo' color='white'/>
          </div>
        </div>
        <Scroll/>
      </div> 

      <div className="slide-inner afrianska-phone">
        <div className="column blue transparent">
          <div className="content">
            <h2>Afrianska</h2>
            <span>Adaptive Layout:</span>
            <p>Layout is made for any size of computers and mobile devices using media queries.</p> 
            <p>The minimum width of 320 pixels allows you to view the site even on the most strange phones.</p>
          </div>
        </div>
      </div> 
    </div>

  )
}
