import React from 'react';
import './slide.scss'

export const History = () => {
	const employment = [
		{
			date: 'May 2019 — now',
			title: 'My-travels.net (social network)',
			responsibilities: 'develop UI-kit and SPA via React.js',
			technologies: 'React, Sass, html',
		},

		{
			date: 'April 2018 — now',
			title: 'Freelance',
			responsibilities: 'develop SPA via React.js, responsive html-code, business logic via jQuery',
			technologies: 'React, Sass, html, css, jQuery, javaScript',
		},

		{
			date: 'May 2016 — September 2016',
			title: 'My-travels.net (social network)',
			responsibilities: 'develop responsive html-code',
			technologies: 'html, css',
		},
	]

	return (
		<div className="slide">
				<div className="content">
					<h2>Employment History</h2>
					{employment.map((item, index)=>{
						return (
							<div key={index} className="history-item">
								<div className="history-item-date">{item.date}</div>
								<div className="history-item-title">{item.title}</div>
								<div className="history-item-description">
									<span>Responsibilities: </span>{item.responsibilities}
								</div>
								<div className="history-item-description">
									<span>Technologies: </span>{item.technologies}
								</div>
							</div>
						)
					})}
				</div>
		</div>
	)
}