import React from 'react';
import './social.scss'

export const Vk = () => (
	<a href="https://vk.com/chugunova_a" target="_blank" className="social">
    <div className="tooltip">Vkontakte</div>
    <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0ZM24.3319 29.6027C24.1101 29.8366 23.6769 29.8839 23.6769 29.8839H22.2425C22.2425 29.8839 19.0778 30.0705 16.29 27.2222C13.2497 24.1146 10.5651 17.949 10.5651 17.949C10.5651 17.949 10.4102 17.5445 10.5781 17.3498C10.767 17.1297 11.2824 17.1159 11.2824 17.1159L14.7111 17.094C14.7111 17.094 15.034 17.1459 15.2652 17.3129C15.4565 17.4512 15.5634 17.7082 15.5634 17.7082C15.5634 17.7082 16.1174 19.0839 16.8511 20.3283C18.2843 22.7584 18.9511 23.2896 19.437 23.0292C20.146 22.6501 19.9336 19.5944 19.9336 19.5944C19.9336 19.5944 19.9465 18.4859 19.5767 17.9916C19.2903 17.6091 18.7503 17.4973 18.512 17.4662C18.3184 17.4408 18.6353 17.0007 19.0461 16.8036C19.6636 16.5075 20.7529 16.4902 22.0406 16.5029C23.0442 16.5133 23.333 16.5744 23.7251 16.6677C24.635 16.8832 24.6066 17.574 24.5453 19.0647C24.527 19.5106 24.5057 20.028 24.5057 20.6256C24.5057 20.7599 24.5017 20.9031 24.4976 21.0504C24.4763 21.8141 24.4519 22.6887 24.9646 23.0142C25.2276 23.1801 25.8697 23.0384 27.4767 20.3606C28.2385 19.0908 28.809 17.5987 28.809 17.5987C28.809 17.5987 28.9346 17.3325 29.1283 17.2185C29.3266 17.1021 29.5943 17.1378 29.5943 17.1378L33.2027 17.1159C33.2027 17.1159 34.2873 16.988 34.4622 17.4696C34.6465 17.9732 34.0572 19.1508 32.5829 21.0796C31.1843 22.9098 30.5037 23.5836 30.5662 24.1796C30.6119 24.615 31.0543 25.0089 31.9032 25.7819C33.6755 27.397 34.1505 28.2468 34.2646 28.451C34.2741 28.4679 34.2811 28.4804 34.2861 28.4885C35.0808 29.7825 33.4046 29.8839 33.4046 29.8839L30.1988 29.9276C30.1988 29.9276 29.5109 30.0613 28.6047 29.4506C28.13 29.131 27.6662 28.609 27.2243 28.1117C26.5496 27.3524 25.9263 26.6509 25.3943 26.8166C24.501 27.0954 24.528 28.984 24.528 28.984C24.528 28.984 24.535 29.3884 24.3319 29.6027Z" />
    </svg>
  </a>
)