import React from 'react'
import './slide.scss'
import {ProjectDetails, Button, Scroll} from '../components'

export const Dashboard = () => {
  const project = {
    description: 'Admin panel for internal system application.',
    responsibilities: ['Creation UI-kit library.'],
    technologies: [
      'React,',
      'Sass.',
    ],
  }

  return (
    <div className="slide long">
      <div className="slide-inner dashboard">
        <div className="column blue transparent">
          <div className="content">
            <h2>UI-kit</h2>
            <ProjectDetails project={project}/>
            <Button href="https://annachu.ru/dashboard" title='View Demo' color='white'/>
          </div>
        </div>
        <Scroll/>
      </div> 

      <div className="slide-inner dashboard-phone">
        <div className="column blue transparent">
          <div className="content">
            <h2>UI-kit</h2>
            <span>Adaptive Layout:</span>
            <p>All components of UI-kit are prepared for display on devices with any screen size (at least 320px).</p> 
          </div>
        </div>
      </div>

    </div>
  )
}

