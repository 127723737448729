import React from 'react';
import './social.scss'

export const Telegram = () => (
  <a href="https://t.me/annchugunova" target="_blank" className="social">
    <div className="tooltip">Telegram</div>
    <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" >
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45ZM18.375 32.8126C18.9222 32.8126 19.1723 32.5692 19.4746 32.2749L19.5 32.2501L22.2026 29.6222L27.825 33.7761C28.8597 34.347 29.6065 34.0514 29.8643 32.8154L33.5552 15.4223C33.9331 13.9073 32.9777 13.2201 31.9878 13.6695L10.3147 22.0266C8.8353 22.62 8.84392 23.4453 10.045 23.8131L15.6068 25.549L28.483 17.4256C29.0909 17.057 29.6488 17.2552 29.1909 17.6616L18.7579 27.0765L18.7579 27.0765L18.7579 27.0765L18.7575 27.0769L18.7578 27.0771L18.375 32.8126Z"/>
    </svg>
  </a>
)