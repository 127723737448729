import React from 'react';
import './slide.scss'
import { Scroll } from '../components'

export const Skills = () => {
	const skills = [
		'React, localStorage,', 
	 	'Html,', 
	 	'Sass, Css,', 
	 	'jQuery, javaScript (base),',
	 	'Figma,',
	 	'Atlassian Jira,',
	 	'Git, Source Tree.',
	]

	const education = [
		{
			date: 'September 2011 — August 2015',
			title: 'Saint-Petersburg State University',
			description: 'Faculty of Applied Mathematics and Control Processes',
			level: '(bachelor degree)',
		},

		{
			date: 'September 2015 — August 2017',
			title: 'Saint-Petersburg State University',
			description: 'Faculty of Applied Mathematics and Control Processes',
			level: '(master degree)',
		},

		{
			date: 'February 2019 — March 2019',
			title: 'Real Web. Social Media Marketing',
			description: '(yandex.direct, google adwords, facebook, instagram, vk)',
		},
	]

	return (
		<div className="slide skills">
			<div className="column">
				<div className="content">
					<h2>Education</h2>
					{education.map((item, index)=>{
						return (
							<div key={index} className="history-item">
								<div className="history-item-date">{item.date}</div>
								<div className="history-item-title">{item.title}</div>
								<div className="history-item-description">{item.description}</div>
								{item.level && <div className="history-item-level">{item.level}</div>}
							</div>
						)
					})}	
					<Scroll/>
				</div>
			</div>
			<div className="column blue">
				<div className="content">
					<h2>Skills</h2>
					<ul>
						{skills.map((item, index)=>{
							return (<li key={index}>{item}</li>)
						})}
					</ul>
				</div>
			</div>
		</div>
	)
}
