import React from 'react'
import './slide.scss'
import {ProjectDetails, Button, Scroll } from '../components'

export const Glossary = () => {
  const project = {
    description: 'On site glossary.annachu.ru  I collect useful information, links, articles, descriptions and examples about html, css, javascript and all related topics.',
    responsibilities: [
      'Develop responsive SPA via React.js.',],
    technologies: [
      'React,',
      'Sass,',
      'Ant Design framework.',
    ],
  }
  
  return (
    <div className="slide long">
      <div className="slide-inner glossary">
        <div className="column blue transparent">
          <div className="content">
            <h2>Glossary</h2>
            <ProjectDetails project={project}/>
            <Button href="https://glossary.annachu.ru/" title='Visit Site' color='white'/>
          </div>
        </div>
        <Scroll />
      </div> 

      <div className="slide-inner glossary-phone">
        <div className="column blue transparent">
          <div className="content">
            <h2>Glossary</h2>
            <span>Details:</span>
            <p>In project I used framework <a className="links" href="https://ant.design/" target="_blank">Ant Design</a>.</p>
            <p>This framework has  a lot of ui-kit components, layouts and flexible configuration options.</p>
            <p>ome pages are empty or does not exist, because I moved articles from my old notes. And it is slowly processed.</p>
          </div>
        </div>
      </div>

    </div>
  )
}
