import React from 'react';
import * as Social from '../social'
import './slide.scss'

export const FirstPage = () => (
	<div className="slide first-page">
		<div className="first-page-content">
			<div>
				<h1>Anna Chugunova</h1>
				<div className="professions">
					<p><span>Model,</span> <span>Blogger</span></p>
					<p>Frontend-developer</p>
				</div>
			</div>

			<div className="social-networks">
				<Social.Telegram />
				<Social.Vk />
				<Social.Email />
				<Social.Github />
			</div>
		</div>
	</div> 
)
