import React from 'react';
import './projectDetails.scss'

export const ProjectDetails = ({project}) => (
	<>
		<div className="project-info-block">
			<div className="project-subtitle">Project:</div>
			<div className="project-description">{project.description}</div>
		</div>

		<div className="project-info-block">
			<div className="project-subtitle">Responsibilities:</div>
			<ul>
				{project.responsibilities.map((item, index)=>{
					return (<li key={index}>{item}</li>)
				})}
			</ul>
		</div>

		<div className="project-info-block">
			<div className="project-subtitle">Using technologies:</div>
			<ul>
				{project.technologies.map((item, index)=>{
					return (<li key={index}>{item}</li>)
				})}
			</ul>
		</div>
	</>
)