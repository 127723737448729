import React from 'react'
import { withRouter } from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider'

import CoreStyles from 'react-awesome-slider/src/core/styles.scss'
import AnimationStyles from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss'
import './app.scss'

import {Header} from './components'
import { 
  FirstPage, 
  Skills, 
  History, 
  Mytravels, 
  Glossary, 
  Dashboard, 
  Afrianska, 
  FashionBlog,
  Cake, 
  Contacts,
} from './slides'

import * as routing from './routing'

class AppContainer extends React.Component {
  media = [
    {
      slug: routing.root,
      children: <FirstPage/>
    },
    {
      slug: routing.education,
      children: <Skills/>
    },
    {
      slug: routing.employment,
      children: <History/>
    },
    /*{
      slug: routing.myTravels,
      children: <Mytravels/>
    },*/
    {
      slug: routing.glossary,
      children: <Glossary/>
    },
    {
      slug: routing.dashboard,
      children: <Dashboard/>
    },
    {
      slug: routing.afrianska,
      children: <Afrianska/>
    },
    {
      slug: routing.fashion,
      children: <FashionBlog/>
    },
    {
      slug: routing.cake,
      children: <Cake/>
    },
    {
      slug: routing.contacts,
      children: <Contacts/>
    }
  ]

  onTransitionEnd = (slideProps) => {
    if (this.props.history.location.pathname === slideProps.currentMedia.slug)
      return
    
    this.props.history.push(slideProps.currentMedia.slug)
  }

  render (){
    return (
      <div className="App" >
        <Header />
        <AwesomeSlider 
          className="slider"
          bullets={false} 
          fillParent={true} 
          animation="scaleOutAnimation"
          onTransitionEnd={this.onTransitionEnd}
          media={this.media}
          selected={this.props.history.location.pathname}
        />
      </div>
    );
  }
}

export const App = withRouter(AppContainer)