import React from 'react'
import './input.scss'

export const Input = (props) => {
	return (
		<input 
			data-type={props.type} 
			className={props.error ? 'error' : ' '}
			value={props.value} 
			onChange={props.onChange} 
			placeholder={props.placeholder}/>
	)
}