import React from 'react'
import * as Social from '../social'
import './slide.scss'
import {Input, Textarea, Button, Scroll } from '../components'

export class Contacts extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      name: '',
      contact: '',
      message: '',
      status: null,
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.dataset.type]: event.target.value,
      status: null,
    })
  }

  onClick = async () => {
  	const { name, contact, message } = this.state

  	if (name.trim() === '') {
  		this.setState({status: 'nameError'})
  		return
  	}

  	if (contact.trim() === '') {
  		this.setState({status: 'contactError'})
  		return
  	}

  	if (message.trim() === '') {
  		this.setState({status: 'messageError'})
  		return
  	}

    let promise = await fetch('https://annachu.ru/api/v1/feedback', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({name, contact, message})
    })
    
    const status = promise.status === 200 ? 'success' : 'requestError'
    this.setState({status})
  }

  render() {
  	const { status } = this.state

    return (
      <div className="slide contacts">
        <div className="column">
          <div className="content">
            <h2>Contacts</h2>
            {status === 'success' 
            ? <div className="alert success">Request was sent!</div>
            : <div>
              <Input 
                type="name" 
                error={status === 'nameError'}
                value={this.state.name} 
                onChange={this.onChange} 
                placeholder="Your name"
              />
              <Input 
                type="contact" 
                error={status === 'contactError'}
                value={this.state.contact} 
                onChange={this.onChange} 
                placeholder="Your email or phone"
              />
              <Textarea 
                type="message" 
                error={status === 'messageError'}
                value={this.state.message} 
                onChange={this.onChange} 
                placeholder="Your message"
              />

              <div className="alert error">{
                this.state.status === 'requestError' && 
                'Some think was wrong! Please, try again.'
              }</div>
              
              <Button onClick={this.onClick} title='Send' color='blue'/>
            </div>
            }

            <Scroll />
          </div>
        </div>
        <div className="column blue">
          <div className="content">
            <h2>Links</h2>
            <p>Need Frontend development?</p> 
            <p>Just send me to <a href="mailto:mail@annachu.ru" target="_blank" className="mail">mail@annachu.ru</a> or use contact form.</p>

            <div className="social-networks">
              <Social.Telegram />
              <Social.Vk />
              <Social.Email />
              <Social.Github />
            </div>
          </div>
        </div>

      </div>
    )
  }
}
