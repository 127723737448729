import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'

import * as routing from '../../routing'

import './header.scss'

export const Header = () => {
  let [ show, setShow ] = useState(false)
  const onClick = () => setShow(!show)

  const onClickNav = () => {
    if(show) setShow(!show)
  }

	return (
    <>
      <div onClick={onClick} className="burger">
        <svg 
          width="22" 
          height="24" 
          viewBox="0 0 32 32" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
        </svg>
      </div>

  	  <nav onClick={onClickNav} className={show ? 'show' : ' '}>
        <NavLink activeClassName="selected" to={routing.root} exact>Home</NavLink>
        <NavLink activeClassName="selected" to={routing.education}>Education</NavLink>
        <NavLink activeClassName="selected" to={routing.employment}>History</NavLink>
        <NavLink activeClassName="selected" to={routing.glossary}>Projects</NavLink>
        <NavLink activeClassName="selected" to={routing.cake}>Cake</NavLink>
        <NavLink activeClassName="selected" to={routing.contacts}>Contacts</NavLink>
      </nav>
    </>
	)
}