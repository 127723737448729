import React from 'react';
import './social.scss'

export const Github = () => (
	<a href="https://github.com/annachugunova" target="_blank" className="social">
    <div className="tooltip">GitHub</div>
    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5002 0C10.0753 0 0 10.0745 0 22.5029C0 32.4455 6.44699 40.8806 15.387 43.8562C16.5115 44.0645 16.9243 43.3681 16.9243 42.7737C16.9243 42.2371 16.9035 40.4644 16.8938 38.584C10.6342 39.9453 9.31335 35.9289 9.31335 35.9289C8.28984 33.3279 6.81511 32.6362 6.81511 32.6362C4.77367 31.2396 6.96899 31.2683 6.96899 31.2683C9.2284 31.4266 10.4181 33.5872 10.4181 33.5872C12.4249 37.0275 15.6817 36.0329 16.9657 35.4579C17.1676 34.0039 17.7507 33.0104 18.3942 32.4488C13.3966 31.8798 8.14303 29.9502 8.14303 21.3276C8.14303 18.8707 9.02198 16.8633 10.4613 15.2874C10.2277 14.7202 9.45754 12.4318 10.6793 9.33215C10.6793 9.33215 12.5687 8.72735 16.8684 11.6388C18.6632 11.1402 20.588 10.8902 22.5002 10.8816C24.4123 10.8902 26.3386 11.1402 28.1368 11.6388C32.4313 8.72735 34.3181 9.33215 34.3181 9.33215C35.5428 12.4318 34.7723 14.7202 34.5387 15.2874C35.9814 16.8633 36.8544 18.8707 36.8544 21.3276C36.8544 29.9707 31.5907 31.8738 26.5805 32.4309C27.3875 33.1293 28.1066 34.4987 28.1066 36.5982C28.1066 39.6088 28.0805 42.0321 28.0805 42.7737C28.0805 43.3725 28.4855 44.0742 29.626 43.8532C38.5612 40.8743 45 32.4421 45 22.5029C45 10.0745 34.9262 0 22.5002 0Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.52199 32.3094C8.47243 32.4216 8.29657 32.4548 8.13635 32.378C7.97316 32.3046 7.8815 32.1522 7.93441 32.04C7.98284 31.9249 8.15908 31.8932 8.3219 31.9696C8.48547 32.0434 8.57862 32.1973 8.52199 32.3094Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.43333 33.326C9.32602 33.4255 9.11625 33.3793 8.97392 33.222C8.82675 33.0651 8.79917 32.8553 8.90797 32.7544C9.01863 32.6549 9.22207 32.7014 9.36962 32.8583C9.51679 33.0171 9.54548 33.2254 9.43333 33.326Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.3205 34.6222C10.1826 34.718 9.95719 34.6282 9.81784 34.4281C9.67998 34.228 9.67998 33.988 9.82082 33.8918C9.96054 33.7957 10.1826 33.8821 10.3238 34.0808C10.4613 34.2839 10.4613 34.5242 10.3205 34.6222Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5359 35.8741C11.4126 36.0101 11.1499 35.9736 10.9576 35.788C10.7609 35.6066 10.7061 35.3491 10.8298 35.2131C10.9546 35.0767 11.2188 35.115 11.4126 35.2991C11.6078 35.4802 11.6674 35.7396 11.5359 35.8741Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2125 36.601C13.1581 36.7773 12.9052 36.8574 12.6503 36.7825C12.3958 36.7053 12.2293 36.4989 12.2807 36.3208C12.3336 36.1434 12.5877 36.0599 12.8444 36.14C13.0985 36.2168 13.2654 36.4218 13.2125 36.601Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0539 36.7361C15.0602 36.9217 14.8441 37.0756 14.5766 37.0785C14.3076 37.0849 14.09 36.9347 14.087 36.7521C14.087 36.5647 14.2983 36.4123 14.5673 36.4078C14.8348 36.4026 15.0539 36.5516 15.0539 36.7361Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7674 36.4443C16.7995 36.6254 16.6135 36.8113 16.3479 36.8605C16.0867 36.9086 15.8449 36.7968 15.8117 36.6172C15.7793 36.4316 15.9686 36.2456 16.2294 36.1976C16.4954 36.1514 16.7335 36.2602 16.7674 36.4443Z"/>
    </svg>
  </a>
)