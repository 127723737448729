import React from 'react';
import './slide.scss'

export const Cake = () => (
	<div className="slide cake">
		<div className="column blue transparent">
			<div className="content">
				<h2>Cake</h2>
				<p>On free time I like to read books and cook beautiful cakes.</p>
				<span>Let’s go-to a cup of tea.</span>
			</div>
		</div>
	</div> 
)
